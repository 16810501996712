export function getDeviceLocale() {
	if (navigator.languages?.length > 0) {
		return navigator.languages[0];
	} else if (navigator.language) {
		return navigator.language;
	}
	return "en-US";
}

export function getDeviceLanguage() {
	return getDeviceLocale().slice(0, 2);
}

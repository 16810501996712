import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";

export default {
	delete: function (alertId: number) {
		// Update local state
		store.dispatch("deleteAlert", alertId);

		// Enqueue API-related mutations
		OfflineQueue.add("alerts.delete", alertId);
	},
	_delete: function (alertId: number) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("Alerts/" + alertId)
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	clearAll: function () {
		// Update local state
		store.dispatch("clearAlerts");

		// Enqueue API-related mutations
		OfflineQueue.add("alerts.clearAll");
	},
	_clearAll: function () {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("Alerts")
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};

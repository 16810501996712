import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";

export default {
	update: function (plantingId: number, value: any) {
		const payload = {
			plantingId,
			value,
			valueType: "averageHeadWeight",
		};

		// Update local state
		store.dispatch("updateUserStatistic", payload);

		// Enqueue API-related mutations
		OfflineQueue.add("averageHeadWeight.update", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "averageHeadWeight.update" &&
				queueItem.payload.plantingId === payload.plantingId
			)
				return 1;
		});
	},
	_update: function (payload: { plantingId: string; value: any }) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("/LotDetails/UpdateAverageHeadWeight/" + payload.plantingId, payload.value)
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};

import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import _ from "lodash";
import seedgreenAPI from "@/lib/seedgreen-api";

export default {
	clear: function (statistic: any) {
		// Update local state
		store.dispatch("clearUserStatistic", statistic);

		// Enqueue API-related mutations
		OfflineQueue.add("userStatistics.clear", statistic, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				(queueItem.endpoint === "userStatistics.clear" || queueItem.endpoint === "userStatistics.update") &&
				queueItem.payload.plantingId === statistic.plantingId &&
				queueItem.payload.valueType === statistic.valueType
			)
				return 1;
		});
	},
	_clear: function (statistic: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("LotDetails/ClearUserStatistic/" + statistic.plantingId, statistic.binName)
						.then((response) => response.data)
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	update: function (statistic: any) {
		// Update local state
		store.dispatch("updateUserStatistic", statistic);

		// Enqueue API-related mutations
		OfflineQueue.add("userStatistics.update", statistic, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				(queueItem.endpoint === "userStatistics.clear" || queueItem.endpoint === "userStatistics.update") &&
				queueItem.payload.plantingId === statistic.plantingId &&
				queueItem.payload.valueType === statistic.valueType
			)
				return 1;
		});
	},
	_update: function (statistic: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("LotDetails/UpdateUserStatistic/" + statistic.plantingId, {
							Value: statistic.value,
							BinName: statistic.binName,
						})
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	batchUpdate: function (statistics: any) {
		// {plantingId, binData = [{binName, userValue, actualValue}]}
		// Update local state
		store.dispatch("updateBatchStatistics", statistics);

		// Enqueue API-related mutations
		OfflineQueue.add("userStatistics.batchUpdate", statistics, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "userStatistics.batchUpdate" && _.isEqual(queueItem.payload, statistics))
				return 1;
		});
	},
	_batchUpdate: function (statistics: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("LotDetails/UpdateStatisticsBatch/" + statistics.plantingId, statistics.binData)
						.then((response) => response.data)
						.then((result) => {
							// Update planting's harvestAcres with result.harvestAcres, if it exists
							if (result && result.harvestAcres)
								store.dispatch("setHarvestAcres", {
									plantingId: statistics.plantingId,
									harvestAcres: result.harvestAcres,
								});

							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};

import { logout } from "@/api/logout";
import seedgreenAPI from "@/lib/seedgreen-api";

// See if the auth token is still valid
function verifyToken() {
	return new Promise<void>((resolve, reject) => {
		seedgreenAPI
			.get("WebApiAuthentication/VerifyToken")
			.then(() => {
				resolve();
			})
			.catch((e) => {
				reject(e);
			});
	});
}

// Does a token error warrant logging out? Right now, the only instance that doesn't is if the connection is lost mid-request
interface StatusError extends Error {
	status?: number;
}
function shouldLogOutFromRequestError(e: unknown) {
	// ERR_CONNECTION_REFUSED
	return !e || (e as StatusError).status !== 0 || !navigator.onLine;
}

export function ensureValidToken() {
	return new Promise<void>((resolve, reject) => {
		// Is our token valid?
		verifyToken()
			// Token is valid
			.then(() => resolve())
			// Token might not be valid -- should we log out?
			.catch((e) => {
				reject(e);

				if (shouldLogOutFromRequestError(e)) logout();
			});
	});
}

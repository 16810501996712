import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";
import store from "@/store";
import userStatistics from "./user-statistics";

export default {
	update: function (harvestAcres: { clearUserStatistics: any; statisticsToClear: any[]; plantingId: number }) {
		// Update local state
		store.dispatch("updateHarvestAcres", harvestAcres);

		// Clear user statistics?
		if (harvestAcres.clearUserStatistics) {
			harvestAcres.statisticsToClear.forEach((statistic: any) => {
				userStatistics.clear(statistic);
			});
		}

		// Enqueue API-related mutations
		OfflineQueue.add("harvestAcres.update", harvestAcres, function duplicateMerger(queueItem) {
			if (
				queueItem.endpoint === "harvestAcres.update" &&
				queueItem.payload.plantingId === harvestAcres.plantingId
			)
				return 1;
		});
	},
	_update: function (harvestAcres: { plantingId: string; harvestAcres: any; clearFlex: any }) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("LotDetails/UpdateHarvestAcres/" + harvestAcres.plantingId, {
							HarvestAcres: harvestAcres.harvestAcres,
							ClearFlex: harvestAcres.clearFlex,
						})
						.then((response) => response.data)
						.then((data) => {
							// Update color map based off of the new harvest date
							store.dispatch("setHeadSizeColorMap", {
								plantingId: harvestAcres.plantingId,
								colorMap: {
									time: new Date().getTime(),
									colorMap: data.plantSizeColorMap,
								},
								sizingGuide: {
									time: new Date().getTime(),
									sizingGuide: data.sizingGuide,
								},
							});

							// Update planting's statistics to match the latest distribution
							if (data.distribution && data.distribution.hasDistribution) {
								store.dispatch("loadPlantingDistribution", {
									plantingId: harvestAcres.plantingId,
									distribution: data.distribution,
								});
							}

							// Update planting's userStatistics with result.userStatistics, if it exists
							if (data.userStatistics)
								store.dispatch("setUserStatistics", {
									plantingId: harvestAcres.plantingId,
									userStatistics: data.userStatistics,
								});

							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};

<template>
	<ion-select
		v-if="show"
		id="customer-switcher"
		:value="customerIdRef"
		@ionChange="changeCustomer(+$event.target.value)"
		v-i18n
		:interface-options="{ header: 'Change Customer' }"
	>
		<ion-select-option v-for="customer in sortedCustomers" :key="customer.id" :value="customer.id">
			{{ customer.name }}
		</ion-select-option>
	</ion-select>
</template>

<script setup lang="ts">
	import { resync } from "@/api/background-sync";
	import { logout } from "@/api/logout";
	import { ensureValidToken } from "@/api/token-validation";
	import router from "@/router";
	import store from "@/store";
	import { resetOnChangeCustomer } from "@/stores/clearState";
	import { IonSelect, IonSelectOption, loadingController, toastController } from "@ionic/vue";
	import { captureException } from "@sentry/vue";
	import { useQueryClient } from "@tanstack/vue-query";
	import { seedgreenAPI } from "seedgreen-shared/api/axios";
	import { useCustomer, useCustomers } from "seedgreen-shared/query/customer";
	import { useUser } from "seedgreen-shared/query/user";
	import { computed, ref, watch } from "vue";

	const { user } = useUser();
	const { customers } = useCustomers(user);
	const { customer } = useCustomer();

	const queryClient = useQueryClient();

	const customerIdRef = ref();

	watch(user, () => (customerIdRef.value = user.value?.customerId));

	const show = computed(() => user.value?.internalUser && customers.value?.length);

	const sortedCustomers = computed(() => {
		return [...(customers.value ?? [])].sort((a, b) => (a.name < b.name ? -1 : 1));
	});

	async function changeCustomer(customerId: number) {
		if (user.value?.customerId === customerId) return; // Detect ion-select thrashing issues :/
		if (!customerId) throw new Error("CustomerId Error");
		if (!navigator.onLine) {
			const toast = await toastController.create({
				message: "Can't change customer while offline",
				color: "danger",
				duration: 5000,
			});
			await toast.present();
			queryClient.invalidateQueries({ queryKey: ["user"] });
			return;
		}

		const loading = await loadingController.create({
			message: "Changing customer...",
		});
		await loading.present();

		try {
			await ensureValidToken();
			await seedgreenAPI.post("User/ChangeCustomer", undefined, { params: { customerId: customerId } });

			loading.message = "Clearing cache...";
			// Clear all necessary state
			resetOnChangeCustomer();
			localStorage.m_logo = "/images/logo-color.png";
			store.dispatch("setLastSync", null); // Indicate that even though we're logged in, state is not valid

			loading.message = "Reloading data...";
			await resync();

			// Go to lot search, so make sure we're not on a customer-specific page
			await router.push({ name: "lot-search" });

			const toast = await toastController.create({
				message: "Customer loaded: " + customer.value?.name,
				color: "primary",
				duration: 3000,
			});
			await toast.present();
		} catch (error) {
			captureException(error);

			const toast = await toastController.create({
				message: "Error changing customer. Please log in and try again.",
				color: "danger",
				duration: 5000,
			});
			await toast.present();

			logout();
		} finally {
			await loading.dismiss();
		}
	}
</script>

import store from "@/store";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";
import { mobileQueryClient } from "@/lib/query-client";
import { toastController } from "@ionic/vue";
import { AxiosError } from "axios";
import { sendMessage } from "@/components/send-message";
import { i18n } from "@/lib/i18n";

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
const SECOND_IN_MILLISECONDS = 1000;

const SYNC_WINDOW_HOURS = 1; // How long to go before resyncing
const SYNC_CHECK_SECONDS = 1; // How often to check if we should resync

/**
 * Make an HTTP GET request for the data from SeedGreen
 * and pass the response to the callback function.
 *
 * @param {any} path the path to the API endpoint
 * @param {any} callback the name of the function to call in store.js
 */
async function httpGet(path: string, callback: string) {
	const response = await seedgreenAPI.get(path, { withCredentials: true });
	return store.dispatch(callback, response.data);
}

// Keeps resync from getting repeatedly called on itself (only 1 instance at a time)
let activeSyncPromise: Promise<void> | undefined;
export async function resync() {
	if (!navigator.onLine) {
		const toast = await toastController.create({
			message: "Cannot refresh: No internet connection",
			duration: 5000,
			color: "danger",
		});
		await toast.present();
		return Promise.resolve();
	}

	activeSyncPromise = activeSyncPromise || _resync();
	return activeSyncPromise;
}

async function _resync(): Promise<void> {
	try {
		if (!store.state.online) return;
		if (!store.state.loggedIn) return;
		await ensureValidToken();
		await mobileQueryClient.invalidateQueries();

		// in general, get data from the last +/- ~4 months
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 365);
		const startDateStr = startDate.toISOString().split("T")[0];

		const endDate = new Date();
		endDate.setDate(endDate.getDate() + 365);
		const endDateStr = endDate.toISOString().split("T")[0];

		await Promise.all([
			httpGet("WebApiAuthentication/GetData", "resync"),
			httpGet(`IrrigationBlocks?startDate=${startDateStr}&endDate=${endDateStr}`, "setIrrigationBlocks"),
			httpGet("Ranch", "setRanches"),
			httpGet("RanchCooler", "setRanchCoolers"),
			httpGet("Season", "setSeasons"),
		]);
	} catch (error) {
		if (!navigator.onLine) {
			// User lost connection mid-resync
			await sendMessage(i18n.global.t("error.resync-no-connection"), 5000, null, "danger");
		} else {
			console.error("Resync error:", error);
			throw error;
		}
	} finally {
		activeSyncPromise = undefined;
	}
}

// Resync to ensure that data does not get stale
let syncInterval: NodeJS.Timeout | undefined;
export default {
	run() {
		syncInterval = setInterval(() => {
			if (syncInterval) clearInterval(syncInterval);
			if (!store.state.loggedIn) return;
			if (
				!store.state.lastSync ||
				new Date().getMilliseconds() - new Date(store.state.lastSync).getMilliseconds() >
					SYNC_WINDOW_HOURS * HOUR_IN_MILLISECONDS
			) {
				console.debug("Background sync: resyncing...");
				// resync just returns the current sync promise if it is already running
				resync();
			}
		}, SYNC_CHECK_SECONDS * SECOND_IN_MILLISECONDS);
	},
	stop() {
		clearInterval(syncInterval);
	},
};

import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "./token-validation";
import store from "@/store";
import { sendMessage } from "@/components/send-message";
import seedgreenAPI from "@/lib/seedgreen-api";

const bundle = {
	savePlantingTicketRecord: function (record: any) {
		// Build virtual id if it doesn't have one
		if (!record.id && !record.virtualId)
			record.virtualId = `p${record.plantingId}-t${record.submittedDate}-u${record.userId}`;

		store.dispatch("savePlantingTicketRecord", record);

		// Enqueue API update
		OfflineQueue.add("plantingTicket.saveRecord", record, function duplicateMerger(queueItem) {
			if (
				queueItem.endpoint === "plantingTicket.saveRecord" &&
				(queueItem.payload.id === record.id || queueItem.payload.virtualId === record.virtualId)
			)
				return 1;
		});
	},
	_savePlantingTicketRecord: function (payload: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("PlantingTicket/Save", payload)
						.then((response) => response.data)
						.then((data) => {
							store.dispatch("savePlantingTicketRecord", data); // Update with new data (id)

							resolve();
						})
						.catch(({ xhr, _status, error }) => {
							sendMessage(
								`Failed to save ticket: ${xhr.status} ${error}.  Please try again...`,
								5000,
								null,
								"warning",
							);
							console.debug("planting-ticket::_save: error", xhr);
							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000, null, "danger");
					reject(e);
				});
		});
	},
	submitPlantingTicketRecord: function (record: any) {
		const payload = structuredClone(record);
		store.dispatch("savePlantingTicketRecord", record);
		store.dispatch("submitPlantingTicketRecord", record);

		// Enqueue API update
		OfflineQueue.add("plantingTicket.submitRecord", payload, function duplicateMerger(queueItem) {
			if (
				queueItem.endpoint === "plantingTicket.submitRecord" &&
				(queueItem.payload.id === payload.id || queueItem.payload.virtualId === payload.virtualId)
			)
				return 1;
		});
	},
	_submitPlantingTicketRecord: function (payload: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("PlantingTicket/SaveAndSubmit", payload)
						.then((response) => response.data)
						.then((data) => {
							// Patch in updated planting information
							if (data.updatedPlanting)
								store.dispatch("updatePlantingAfterSubmitTicket", data.updatedPlanting);

							// Update the saved ticket record
							if (data.submittedRecord) store.dispatch("savePlantingTicketRecord", data.submittedRecord);

							resolve();
						})
						.catch(({ xhr, status, error }) => {
							sendMessage(
								`Failed to submit ticket: ${xhr.status} ${error}.  Please try again...`,
								5000,
								null,
								"warning",
							);
							console.debug("planting-ticket::_submit: error", xhr);
							store.dispatch("revertSubmitPlantingTicketRecord", payload);
							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000, null, "danger");
					store.dispatch("revertSubmitPlantingTicketRecord", payload);
					reject(e);
				});
		});
	},
	deletePlantingTicketRecord: function (record: any) {
		store.dispatch("deletePlantingTicketRecord", record);

		// Enqueue API update
		OfflineQueue.add("plantingTicket.deleteRecord", record, function duplicateMerger(queueItem) {
			if (queueItem.endpoint === "plantingTicket.deleteRecord" && queueItem.payload.id === record.id) return 1;
		});
	},
	_deletePlantingTicketRecord: function (payload: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("PlantingTicket/Delete", payload)
						.then((response) => response.data)
						.then((_data) => {
							resolve();
						})
						.catch(({ xhr, status, error }) => {
							sendMessage(
								`Failed to delete ticket: ${xhr.status} ${error}.  Please try again...`,
								5000,
								null,
								"warning",
							);
							console.debug(xhr);

							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000, null, "danger");
					reject(e);
				});
		});
	},
	deletePlantingTicketDrafts: function (plantingId: any) {
		store.dispatch("deletePlantingTicketDrafts", plantingId);

		// Enqueue API update
		OfflineQueue.add(
			"plantingTicket.deleteDrafts",
			{ plantingId: plantingId },
			function duplicateMerger(queueItem) {
				if (queueItem.endpoint === "plantingTicket.deleteDrafts" && queueItem.payload.plantingId === plantingId)
					return 1;
			},
		);
	},
	_deletePlantingTicketDrafts: function (payload: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("PlantingTicket/DeleteDrafts", payload.plantingId)
						.then((response) => response.data)
						.then((_data) => {
							resolve();
						})
						.catch(({ xhr, status, error }) => {
							sendMessage(
								`Failed to delete ticket: ${xhr.status} ${error}.  Please try again...`,
								5000,
								null,
								"warning",
							);
							console.debug(xhr);

							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000, null, "danger");
					reject(e);
				});
		});
	},
};

export default bundle;

import { createI18n, I18nOptions } from "vue-i18n";
import { getDeviceLanguage } from "./locale";

import en from "seedgreen-shared/lang/en.json";
import es from "seedgreen-shared/lang/es.json";
import datetimeFormats from "seedgreen-shared/lang/datetimeFormats";

// I18n localization plugin setup
export const i18n = createI18n({
	allowComposition: true, // Note: this option will be removed in i18n v10
	// watching this (dead) thread about suppressing the warning:
	// https://github.com/intlify/vue-i18n/issues/1523
	locale: localStorage.locale || getDeviceLanguage(),
	fallbackLocale: "en",
	messages: { en, es },
	datetimeFormats: datetimeFormats as I18nOptions["datetimeFormats"],
});

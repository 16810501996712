import harvestOverview from "@/api/harvest-overview";
import headSizeColorMap from "@/api/head-size-color-map";
import alerts from "@/api/alerts";
import notes from "@/api/notes";
import userStatistics from "@/api/user-statistics";
import harvestAcres from "@/api/harvest-acres";
import averageHeadWeight from "@/api/average-head-weight";
import bulkBins from "@/api/bulk-bins";
import bulkWeight from "@/api/bulk-weight";
import taskRequest from "@/api/task-request";
import plantingTicket from "@/api/planting-ticket";
import tasks from "./tasks";

const apis = {
	"headSizeColorMap.get": headSizeColorMap._get,
	"alerts.delete": alerts._delete,
	"alerts.clearAll": alerts._clearAll,
	"harvestOverview.createRanchYield": harvestOverview._createRanchYield,
	"notes.create": notes._create,
	"notes.delete": notes._delete,
	"notes.edit": notes._edit,
	"notes.uploadImages": notes._uploadImages,
	"userStatistics.clear": userStatistics._clear,
	"userStatistics.update": userStatistics._update,
	"userStatistics.batchUpdate": userStatistics._batchUpdate,
	"harvestAcres.update": harvestAcres._update,
	"plantingTicket.saveRecord": plantingTicket._savePlantingTicketRecord,
	"plantingTicket.submitRecord": plantingTicket._submitPlantingTicketRecord,
	"plantingTicket.deleteRecord": plantingTicket._deletePlantingTicketRecord,
	"plantingTicket.deleteDrafts": plantingTicket._deletePlantingTicketDrafts,
	"taskRequest.createOrUpdate": taskRequest._createOrUpdate,
	"averageHeadWeight.update": averageHeadWeight._update,
	"bulkBins.update": bulkBins._update,
	"bulkWeight.update": bulkWeight._update,
	"tasks.put": tasks._put,
};

export default apis;

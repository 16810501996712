export function sendMessage(message: string, duration: number, buttons?: any, color?: any) {
	return new Promise<void>((resolve) => {
		let toastContainer = document.getElementById("toast-container");

		if (!toastContainer) {
			toastContainer = document.createElement("div");
			toastContainer.setAttribute("id", "toast-container");

			document.body.appendChild(toastContainer);
		}

		const toast = document.createElement("ion-toast");
		toast.message = message;
		toast.duration = duration === undefined ? 3000 : duration;
		toast.buttons = buttons;
		toast.color = color;

		toastContainer.appendChild(toast);
		toast.present();

		const interval = setInterval(() => {
			if (toast.shadowRoot?.childNodes.length) {
				clearInterval(interval);

				const childNode = toast.shadowRoot.childNodes[0];
				// @ts-expect-error FIXME:
				if (childNode.classList.contains("toast-wrapper")) {
					// @ts-expect-error FIXME:
					toast.style.height = `${childNode.clientHeight + 8}px`;
					toast.style.position = "relative";
				}
			}
		}, 5);

		resolve();
	});
}
